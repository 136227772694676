// Fonts
@import url('https://fonts.bunny.net/css?family=Titillium+Web:400,700');

// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap/scss/bootstrap';

//Tags
@import '@yaireo/tagify/dist/tagify.css';
//DragSort
@import '@yaireo/dragsort/dist/dragsort.css';